import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../Config/Theme";

const Div = styled.div`
    .banner{
          background-color: ${props => props.theme.colors.white};  
            .text-box-container{
            padding: 2em;
            @media only screen and (min-width: ${props => props.theme.sizes.portrait_phone} ) and (max-width: ${props => props.theme.sizes.landscape_phones}) {
                    padding: 1em;
                    margin: 0px;
                }

                @media only screen and (max-width: ${props => props.theme.sizes.portrait_phone}) {
                    padding: 1em;
                    margin:0px;
                }
                .text-box{
                padding: 1em;
                .display-4{
                    margin-bottom: .5em;

                    @media only screen and (min-width:${props => props.theme.sizes.portrait_phone} ) and (max-width: ${props => props.theme.sizes.landscape_phone}) {
                        font-size: 2em;
                    }

                    @media only screen and (max-width: ${props => props.theme.sizes.portrait_phone}) {
                        font-size: 1.75em;
                    }
                }
                .lead{

                    @media only screen and (min-width: ${props => props.theme.sizes.portrait_phone}) and (max-width: ${props => props.theme.sizes.landscape_phone}) {
                    font-size: 1em;
                    }

                    @media only screen and (max-width: ${props => props.theme.sizes.portrait_phone}) {
                    font-size: 1em;
                    }
                }
                .strengths{
                    color: ${props => props.theme.colors.light_blue};


                    @media only screen and (min-width: ${props => props.theme.sizes.portrait_phone}) and (max-width: ${props => props.theme.sizes.landscape_phone}) {
                    font-size: 1em;
                    }

                    @media only screen and (max-width: ${props => props.theme.sizes.portrait_phone}) {
                    font-size: 1em;
                    }
                }

            }
        }

        .employee{
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                border-radius: 50%;
                border: 3px solid ${props => props.theme.colors.orange};
                margin: 2em;
            }
        } 

`

class Luke extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <section className="banner px-3 pt-3 pb-3" id="luke">
                        <div className="row mx-0 shadow bg-white">
                            <div className="col-md-4 col-sm-12 px-5 bg-light d-flex align-items-center">
                                <img className="img-fluid employee mx-auto" src="/assets/images/team/luke.jpg" alt="luke" id="img-luke" />
                            </div>
                            <div className="col-md-8 col-sm-12 text-box-container">
                                <div className="text-box">
                                    <div className="description">
                                        <h1 className="display-4">Luke Benson</h1>
                                        <blockquote>
                                            <p className="lead">MBA<br/>Human Capital Consultant</p>
                                            <p className="strengths">Learner | Achiever | Arranger | Analytical | Self-Assurance</p>
                                        </blockquote>

                                        <p>Luke has over twenty five years of experience and brings specialized expertise in leading investigations, risk prevention, liability assessment, mitigation and solutions management, workforce safety and emergency response, leadership training and development, professional coaching, team development and cohesion, compensation and data analysis, policy and procedure development, talent acquisition and onboarding, cognitive training development programming, operational leadership and change management. Luke has a successful proven track record in developing and implementing strategic and operational methodologies and with high analytical skills, he naturally utilizes fact checks and facts gathering in root cause finding initiatives. </p>

                                        <p>Luke earned a Master of Business Administration from Baylor University and a B.S. in Criminal Justice from the University of North Texas.  Luke also holds a Master Peace Officers license in the State of Texas and is a Cognitive Interview Specialist.</p>

                                        <p>For fun, Luke loves to spend time with his wife, their four wonderful kids, two good dogs (most of the time) and a cat.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Luke;